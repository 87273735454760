<template>
  <div>
    <div class="cases">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">产品系统</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="caselist">
        <ul>
          <li @click="routerlink(item.articleId,index)" v-for="(item, index) in list[currpage - 1]" :key="index">
            <div class="caseimg">
              <img :src="item.articleCover" alt="" />
            </div>
            <div class="casetit">{{item.articleTitle}}</div>
          </li>
          <!-- <li @click="routerlink(index)">
            <div class="caseimg">
              <img
                src="../../assets/images/07运动捕捉/产品系统/Xsens MVN 惯性动作捕捉.png"
                alt=""
              />
            </div>
            <div class="casetit">Xsens MVN 惯性动作捕捉</div>
          </li>
          <li @click="routerlink(index)">
            <div class="caseimg">
              <img
                src="../../assets/images/07运动捕捉/产品系统/Faceware面部表情捕捉.png"
                alt=""
              />
            </div>
            <div class="casetit">Faceware面部表情捕捉</div>
          </li> -->
        </ul>
        <div class="pagination">
          <div class="prevbtn" @click="prevpage()"></div>
          <div class="page">
            <ul>
              <li v-for="(item, index) in pages" :key="index" :class="index + 1 === currpage ? 'hover' : ''" @click="choosepage(index)"></li>
            </ul>
          </div>
          <div class="nextbtn" @click="nextpage()"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user.js";
export default {
  data() {
    return {
      bannerimg: "",
      list: "",
      pages: 0,
      currpage: 1,
      timer: "",
    };
  },
  mounted() {
    // this.startAutoSwitch(); // 页面加载完成后开始自动分页
    this.getcompany();
  },
  created() {},
  methods: {
    //   startAutoSwitch() {
    //     console.log(this.pages,'1111');
    //   this.timer = setInterval(() => {
    //     this.nextpage();
    //   }, 300); // 每隔3秒自动切换下一页
    // },
    // stopAutoSwitch() {
    //   clearInterval(this.timer);
    // },
    prevpage() {
      if (this.currpage > 1) {
        this.currpage--;
      } else {
        this.currpage = this.pages;
      }
    },
    nextpage() {
      if (this.currpage < this.pages) {
        this.currpage++;
      } else {
        this.currpage = 1;
      }
    },
    choosepage(index) {
      this.currpage = index + 1;
    },
    routerlink(id, index) {
      this.$router.push({
        path: `/sport/system/systemdetail`,
        query: {
          id: id,
          index: index,
        },
      });
    },
    //获取公司简介
    async getcompany() {
      let params = {
        barId: 9,
      };
      const data = await getbannerdetail(params);
      this.list = data.data.children[0].portalArticleList;
      var res = data.data.children[0].portalArticleList;
      var arr = [];
      for (let i = 0; i < Math.ceil(res.length / 3); i++) {
        arr[i] = new Array();
        for (let j = i * 3; j < (i + 1) * 3; j++) {
          if (res[j]) {
            arr[i].push(res[j]);
          }
        }
      }
      for (let k = 0; k < arr.length; k++) {
        for (let t = 0; t < arr[k].length; t++) {
          let text = arr[k][t].articleCover;
          arr[k][t].articleCover = "http://36.152.65.166:10008" + text;
        }
      }
      this.list = arr;
      this.pages = Math.ceil(res.length / 3);
      // for (let i = 0; i < this.list.length; i++) {
      //  let text=this.list[i].articleCover
      //  this.list[i].articleCover='http://36.152.65.166:10008'+text

      // }
    },
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
.cases {
  .pagination {
    width: 320px;
    margin: 0px auto;
    margin-top: -50px;
    margin-bottom: 50px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page {
      width: 30%;
      height: 26px;

      ul {
        padding: 0;
        margin: 0;
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        li {
          cursor: pointer;

          width: 10px;
          height: 10px;
          background-color: #cccccc;
          border-radius: 50%;
        }
      }
      .hover {
        background-color: #666666;
      }
    }
    .prevbtn,
    .nextbtn {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
    .prevbtn {
      background: url("~@/assets/images/01首页/上一页1.png");
      background-size: contain !important;
    }
    .nextbtn {
      background: url("~@/assets/images/01首页/下一页1.png");
      background-size: contain !important;
    }
    .prevbtn:hover {
      background: url("~@/assets/images/01首页/上一页2.png");
      background-size: contain !important;
    }
    .nextbtn:hover {
      background: url("~@/assets/images/01首页/下一页2.png");
      background-size: contain !important;
    }
  }
  .caselist {
    width: 1500px;
    margin: 0 auto;
    ul {
      width: 100%;
      margin-bottom: 112px;
      margin-top: 59px;
      display: flex;
      padding: 0;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        height: 378px;
        width: 460px;
        background-color: #e8eaf2;
        text-align: center;
        margin-left: 17.5px;
        margin-right: 17.5px;

        .casetit {
          height: 78px;
          line-height: 78px;
          font-size: 20px;
        }
        .caseimg {
          width: 100%;
          height: 300px;
          box-sizing: border-box;
          border: 1px solid gray;
          z-index: 222;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid white;
            z-index: 1;
          }
        }
      }
      li:hover {
        cursor: pointer;
        .casetit {
          background-color: #0088ea;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
}
.product_title {
  width: 1500px;
  margin: 0px auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tit1 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 13px;
    }
    .tit2 {
      width: 60px;
      height: 4px;
      background-color: #0088ea;
    }
  }
}
</style>